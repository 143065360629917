import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  ChevronRightIcon,
  DashboardIcon,
  InsightsIcon,
  NotificationsIcon,
  TransactionsIcon,
  TripplexLogo,
  UsersIcon,
} from '../../../assets';

const sidebarLinks = [
  {
    name: 'Dashboard',
    link: '/',
    icon: DashboardIcon,
  },
  {
    name: 'Transactions',
    link: '/transactions',
    icon: TransactionsIcon,
  },
  {
    name: 'Users',
    link: '/users',
    icon: UsersIcon,
  },
  {
    name: 'Notification Center',
    link: '/notifications',
    icon: NotificationsIcon,
  },
  {
    name: 'Insights',
    link: '/insights',
    icon: InsightsIcon,
  },
];

const Sidebar = () => {
  const { pathname } = useLocation();
  return (
    <nav className="py-8 bg-[#F8FAFC] w-[261px] h-screen sticky left-0 top-0">
      <img className="px-8" src={TripplexLogo} alt="tripplex logo" />
      <div className="mt-10">
        {sidebarLinks.map(({ name, link, icon }) => (
          <Link
            className={`block px-8 py-5 flex items-center text-sm flex items-center justify-between ${
              pathname === link ? 'text-black font-bold' : 'text-[#475467]'
            }`}
            to={link}
            key={name}
          >
            <span className="flex items-center">
              <img className="mr-2" src={icon} alt={name} />
              {name}
            </span>

            <img src={ChevronRightIcon} alt={name} />
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default Sidebar;
