import React from 'react';
import { Route, Routes as RoutesRRD } from 'react-router-dom';
import { lazyComponent } from '../../common/utils';

const Home = lazyComponent('Home', import('../../pages/Home/Home'));
const Login = lazyComponent('Login', import('../../pages/Login'));
const Insights = lazyComponent('Insights', import('../../pages/Insights'));
const Transactions = lazyComponent('Transactions', import('../../pages/Transactions'));
const Notifications = lazyComponent('Notifications', import('../../pages/Notifications'));
const Users = lazyComponent('Users', import('../../pages/Users'));
const PageNotFound = lazyComponent('PageNotFound', import('../../pages/PageNotFound/PageNotFound'));

export const Routes = (): JSX.Element => (
  <RoutesRRD>
    <Route element={<Home />} path="/" />
    <Route element={<Login />} path="/login" />
    <Route element={<Insights />} path="/insights" />
    <Route element={<Users />} path="/users" />
    <Route element={<Notifications />} path="/notifications" />
    <Route element={<Transactions />} path="/transactions" />
    <Route element={<PageNotFound />} path="*" />
  </RoutesRRD>
);
