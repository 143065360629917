import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { lazyComponent } from '../../common/utils';
import { ErrorBoundarySuspense } from '../ErrorBoundarySuspense/ErrorBoundarySuspense';
import Sidebar from './Sidebar';

// const Footer = lazyComponent('Footer', import('../Footer/Footer'));
const Header = lazyComponent('Header', import('../Header/Header'));

export const Layout = ({ children }: PropsWithChildren<Record<string, unknown>>): JSX.Element => {
  const { pathname } = useLocation();
  return (
    <ErrorBoundarySuspense>
      <div className="flex justify-stretch">
        {pathname !== '/login' && <Sidebar />}
        <div className="grow">
          {pathname !== '/login' && <Header />}
          <div className={pathname !== '/login' ? 'p-7' : ''}>{children}</div>
        </div>
      </div>
    </ErrorBoundarySuspense>
  );
};
